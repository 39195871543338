.masthead {
  position: relative;
  overflow: hidden;
  padding-top: calc(7rem + 72px);
  padding-bottom: 7rem;
  background: $primary;
  background: linear-gradient(0deg, $primary 0%, $secondary 100%);

  background: url(../img/mobile_masthead.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  .masthead-content {
    z-index: 1;
    position: relative;
    .masthead-heading {
      font-size: 3rem;
    }
    .masthead-subheading {
      font-size: 1.5rem;
      color: $fontHeaderSecondary;
    }
  }
  height: 100vh;
  &.confirmed {
    height: 100vh;
  }
}

@media (min-width: 640px) {
  .masthead {
    background: url(../img/masthead.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
  }
}
@media (min-width: 992px) {
  .masthead {
    padding-top: calc(10rem + 55px);
    padding-bottom: 10rem;
    .masthead-content {
      .masthead-heading {
        font-size: 4rem;
      }
      .masthead-subheading {
        font-size: 2rem;
      }
    }
  }
  height: 100vh;
  &.confirmed {
    height: 100vh;
  }
}