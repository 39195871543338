body {
  @include body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
}

.btn-xl {
  text-transform: uppercase;
  padding: 1.5rem 3rem;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.bg-black {
  background-color: $black !important;
}

.rounded-pill {
  border-radius: 5rem;
}