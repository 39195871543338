.row {
  div {
    // border: 1px solid red;
  }
}

#attend {
  h2 {
    margin-bottom: 2rem;
  }
  p {
    margin: 3rem 0;
  }
  form {
    .form-group {
      // max-width: 400px;
    }

    ::placeholder {
      text-align: center;
    }
    label {
      // padding: 0 12px;
      font-size: 0.8rem;
    }
    input {
      border-radius: 0;
    }
    button {
      font-size: 16px;
      color: $fontPrimary;
      text-align: center;
      background: $secondary;
      color: $fontSecondary;
      border-radius: 0;
    }
    .no-padding {
      padding: 0;
    }
  }
}

#speakers {
  h2 {
    margin-bottom: 4rem;
  }
  .speaker {
    img {
      width: 80%;
      padding: 10%;
      border-radius: 50%;
    }
  }
}

#faq {
  .faq-row{
    margin: 40px 0;
  }
  .accordion {
    .card {
      border: 0;
      box-shadow: none;
      .card-header{
        background: none;
      }
    }
    h5 {
      .btn {
        text-align: center;
        color: $fontHeader;
      }
    }
  }
}
footer {
  background: $fontHeader;
}